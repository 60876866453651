import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  banner: {
    boxSizing: 'border-box',
    // border: 'solid 2px rgb(33, 150, 243)',
    // padding: 16,
    marginTop: 16,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {},
  },
  bannerImage: {
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
    // maxWidth: 230,
  },
  socialIcon: {
    width: 40,
    height: 40,
  },
  socialList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  socialItem: {
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
    '& p': {
      color: '#233f69',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}))

export default function Banner() {
  const classes = useStyle()
  return (
    <div className={classes.banner}>
      <a
        href="/eventi/8a599dfc-3f5a-4bb7-a803-c5ea890041f4"
        rel="noreferrer"
        // target="_blank"
      >
        <img
          className={classes.bannerImage}
          // src={'banner-community.png'}
          src={'/banner-oncologia.png'}
          alt=""
        />
        {/* </a> */}
        {/* <a
        href="/eventi-in-presenza?area=LOMBARDIA"
        rel="noreferrer"
        // target="_blank"
      > */}
        <div style={{ paddingLeft: 16 }}>
          {/* <Typography
            variant="h3"
            style={{ fontWeight: 600, color: '#233f69' }}
          > */}
          {/* Bromatech Italia: ora sui social per parlare di scienza! */}
          {/* MILANO, 19.10.2024 dalle 10.00 alle 12.30
            <br />
            “Glutine, microbiota intestinale e malassorbimento: aspetti clinici
            e integrativi”
            <br />
            Relatore: Dott. Marcello Romeo
            <br />
            <u>ISCRIZIONE</u> */}
          {/* </Typography> */}
          {/* <ul className={classes.socialList}>
          <li className={classes.socialItem}>
            <a
              href="https://www.facebook.com/bromatechitalia/"
              rel="noreferrer"
              target="_blank"
            >
              <img className={classes.socialIcon} src={'icon-fb.png'} alt="" />
              <Typography variant="body1">@bromatechitalia</Typography>
            </a>
          </li>
          <li className={classes.socialItem}>
            <a
              href="https://www.instagram.com/bromatech_italia/"
              rel="noreferrer"
              target="_blank"
            >
              <img className={classes.socialIcon} src={'icon-ig.png'} alt="" />
              <Typography variant="body1">@bromatech_italia</Typography>
            </a>
          </li>
          <li className={classes.socialItem}>
            <a
              href="https://www.linkedin.com/company/bromatech-italia/"
              rel="noreferrer"
              target="_blank"
            >
              <img className={classes.socialIcon} src={'icon-ln.png'} alt="" />
              <Typography variant="body1">@bromatechitalia</Typography>
            </a>
          </li>
        </ul> */}
        </div>
      </a>
    </div>
  )
}
